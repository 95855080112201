<template>
    <div class="content-library-create">
        <PageHeading :breadcrumbs="breadcrumbs" title="Name Convo" />

        <form>
            <Input
                id="name"
                v-model="name"
                type="text"
                name="name"
                label="Convo Name"
                :max="152"
                :show-remaining-char="true"
                :disabled="false"
                :errors="[]"
            />
        </form>

        <Stepper
            show-back-button
            show-next-button
            :nav-is-opened="navIsOpened"
            :next-button-is-disabled="name === ''"
            @backButtonOnClick="onBackButtonClick"
            @nextButtonOnClick="onNextButtonClick"
        />

        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="showCancelDialog"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made to the convo.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>
    </div>
</template>

<script>
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';
import { mapGetters, mapActions } from 'vuex';
import Stepper from '@/components/ui/Stepper';
import Input from '@/components/forms/Input';
import Dialog from '@/components/ui/Dialog';
import { Routes } from '@/enums/index.ts';
import ConvoTypesList from '@/json/convoTypes';

export default {
    name: 'ContentLibraryCreate',
    components: {
        PageHeading,
        Stepper,
        Dialog,
        Input,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
        convoType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            breadcrumbs: [
                new Breadcrumb('Content Library', { name: Routes.ContentLibraryList }),
                new Breadcrumb('Name convo'),
            ],
            showCancelDialog: false,
        };
    },
    computed: {
        ...mapGetters('convoDetails', ['convoDetailsView']),
        name: {
            get() {
                return this.convoDetailsView.convo.name;
            },
            set(value) {
                this.setConvoName(value);
            },
        },
    },
    created() {
        if (!this.convoType) {
            this.$router.push({ name: Routes.ContentLibraryList });
        }
        this.resetConvoDetailsState();
        this.setConvoType(this.convoType);
    },
    methods: {
        ...mapActions('convoDetails', [
            'saveConvo',
            'setConvoName',
            'validateConvo',
            'setConvoType',
            'resetConvoDetailsState',
        ]),

        onBackButtonClick() {
            if (this.name === '') {
                this.$router.push({ name: Routes.ContentLibraryList });
                return;
            }
            this.showCancelDialog = true;
        },
        leaveDialogOnClose() {
            this.$router.push({ name: Routes.ContentLibraryList });
            this.name = '';
        },
        leaveDialogOnConfirm() {
            this.showCancelDialog = false;
        },
        async onNextButtonClick() {
            if (await this.validateConvo()) {
                const isGlobal = ConvoTypesList.find(
                    (convoType) => convoType.name === this.convoType,
                )?.isGlobal;
                await this.saveConvo({ name: this.name, isGlobal, isContentLibrary: true });
                this.$router.push({
                    name: Routes.ContentLibraryMessages,
                    params: {
                        convoId: this.convoDetailsView.convo.id,
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.content-library-create {
    margin: 0 auto;
    max-width: 600px;
}
</style>
